<template>
    <div class="flex w-full flex-col gap-[60px] lg:gap-[80px]">
        <!-- title -->
        <div class="flex w-full px-8 lg:px-[120px]">
            <span
                class="mx-auto w-full max-w-[1200px] text-2xl font-extrabold text-[#fff] lg:text-5xl"
                >ABOUT PROJECT</span
            >
        </div>
        <!-- card row -->
        <CardRow />
        <!-- statistics -->
        <div
            class="relative flex w-full items-center justify-center gap-7 lg:gap-[112px]"
        >
            <!-- line -->
            <div
                class="absolute left-0 top-[50px] h-[2px] w-full bg-[#250657] lg:top-[60px]"
            ></div>
            <!-- statistics 1 -->
            <div class="z-1 relative flex flex-col items-center gap-5 lg:gap-7">
                <img
                    src="assets/imgs/views/home/section2/statistics-icon1.png"
                    alt=""
                    class="w-[100px] lg:w-[120px]"
                />
                <div class="flex flex-col items-center gap-3">
                    <span
                        class="text-nowrap text-center text-2xl font-bold text-[#fff] lg:text-3xl"
                        >1,890</span
                    >
                    <span
                        class="text-nowrap text-center text-sm text-[#fff] lg:text-base"
                        >Groups</span
                    >
                </div>
            </div>
            <!-- statistics 1 -->
            <div class="z-1 relative flex flex-col items-center gap-5 lg:gap-7">
                <img
                    src="assets/imgs/views/home/section2/statistics-icon2.png"
                    alt=""
                    class="w-[100px] lg:w-[120px]"
                />
                <div class="flex flex-col items-center gap-3">
                    <span
                        class="text-nowrap text-center text-2xl font-bold text-[#fff] lg:text-3xl"
                        >856</span
                    >
                    <span
                        class="text-nowrap text-center text-sm text-[#fff] lg:text-base"
                        >DAO Solved</span
                    >
                </div>
            </div>
            <!-- statistics 1 -->
            <div class="z-1 relative flex flex-col items-center gap-5 lg:gap-7">
                <img
                    src="assets/imgs/views/home/section2/statistics-icon3.png"
                    alt=""
                    class="w-[100px] lg:w-[120px]"
                />
                <div class="flex flex-col items-center gap-3">
                    <span
                        class="text-nowrap text-center text-2xl font-bold text-[#fff] lg:text-3xl"
                        >$956</span
                    >
                    <span
                        class="text-nowrap text-center text-sm text-[#fff] lg:text-base"
                        >Guaranteed</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardRow from "./components/CardRow.vue";

export default {
    name: "Section2",
    components: {
        CardRow,
    },
};
</script>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Antd from "ant-design-vue";
import VueSplide from "@splidejs/vue-splide";
import "ant-design-vue/dist/antd.css";
import "./assets/tailwind.css";
import "@splidejs/splide/dist/css/splide.min.css";

Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(VueSplide);

new Vue({
    router,
    render: function (h) {
        return h(App);
    },
}).$mount("#app");

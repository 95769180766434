<template>
    <div class="flex w-full px-[120px]">
        <div
            class="mx-auto flex w-full max-w-[1200px] flex-wrap items-center justify-center gap-[10px] overflow-hidden"
        >
            <div class="flex justify-center gap-[10px]">
                <!-- member 1 -->
                <div
                    class="content flex h-[400px] w-[250px] flex-col gap-5 rounded-2xl bg-[#270656] p-4"
                >
                    <!-- avatar -->
                    <img
                        src="assets/imgs/views/home/section4/avatar1.png"
                        alt=""
                        class="w-full rounded-xl"
                    />
                    <!-- name & @ -->
                    <div class="flex w-full flex-col gap-1">
                        <span class="text-lg font-bold text-[#fff]"
                            >John Karony</span
                        >
                        <span class="text-sm text-[#8E7DA7]"
                            >@johnguragoo_tonsafety</span
                        >
                    </div>
                    <!-- position -->
                    <span class="text-sm text-[#fff]"
                        >Cryptocurrency Trading</span
                    >
                </div>
                <!-- member 2 -->
                <div
                    class="content flex h-[400px] w-[250px] flex-col gap-5 rounded-2xl bg-[#270656] p-4"
                >
                    <!-- avatar -->
                    <img
                        src="assets/imgs/views/home/section4/avatar2.png"
                        alt=""
                        class="w-full rounded-xl"
                    />
                    <!-- name & @ -->
                    <div class="flex w-full flex-col gap-1">
                        <span class="text-lg font-bold text-[#fff]"
                            >Thomas Smith</span
                        >
                        <span class="text-sm text-[#8E7DA7]"
                            >@thomaswooloo_tonsafety</span
                        >
                    </div>
                    <!-- position -->
                    <span class="text-sm text-[#fff]"
                        >Payment / E-Commerce</span
                    >
                </div>
            </div>
            <div class="flex justify-center gap-[10px]">
                <!-- member 3 -->
                <div
                    class="content flex h-[400px] w-[250px] flex-col gap-5 rounded-2xl bg-[#270656] p-4"
                >
                    <!-- avatar -->
                    <img
                        src="assets/imgs/views/home/section4/avatar3.png"
                        alt=""
                        class="w-full rounded-xl"
                    />
                    <!-- name & @ -->
                    <div class="flex w-full flex-col gap-1">
                        <span class="text-lg font-bold text-[#fff]"
                            >Math Trella</span
                        >
                        <span class="text-sm text-[#8E7DA7]"
                            >@math_tonsafety</span
                        >
                    </div>
                    <!-- position -->
                    <span class="text-sm text-[#fff]"
                        >Payment / Cryptocurrency</span
                    >
                </div>
                <!-- member 4 -->
                <div
                    class="content flex h-[400px] w-[250px] flex-col gap-5 rounded-2xl bg-[#270656] p-4"
                >
                    <!-- avatar -->
                    <img
                        src="assets/imgs/views/home/section4/avatar4.png"
                        alt=""
                        class="w-full rounded-xl"
                    />
                    <!-- name & @ -->
                    <div class="flex w-full flex-col gap-1">
                        <span class="text-lg font-bold text-[#fff]"
                            >Rammel Donnald</span
                        >
                        <span class="text-sm text-[#8E7DA7]"
                            >@Rammel_tonsafety</span
                        >
                    </div>
                    <!-- position -->
                    <span class="text-sm text-[#fff]">Internet / Games</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
    name: "CardRowDesktop",
    components: {
        Splide,
        SplideSlide,
    },
};
</script>

<style lang="scss">
.splide__track {
    overflow: visible !important;
}
</style>

<template>
    <div
        class="mx-auto flex h-[80px] w-screen max-w-[1200px] items-center justify-between gap-5 pr-8 lg:h-[120px]"
    >
        <!-- logo -->
        <a href="/">
            <img
                src="assets/imgs/utils/logo/ton_safety.png"
                alt="ton_safety"
                class="w-[160px] lg:w-[280px]"
            />
        </a>
        <div class="flex items-center gap-4 lg:gap-8">
            <!-- navigators -->
            <div class="hidden gap-8 lg:flex">
                <a
                    v-for="(item, index) in menuItems"
                    :key="index"
                    href=""
                    class="text-center text-xl text-[#fff] transition-all duration-300 hover:scale-110 hover:text-[#fff]"
                    >{{ item.text }}</a
                >
            </div>
            <!-- stake button -->
            <button
                @click="stakeNowBtnOnclick"
                class="flex items-center justify-center rounded-full border-2 border-[#fff] bg-none px-4 py-1 text-base font-bold text-[#fff] transition-all duration-300 hover:scale-110"
            >
                STAKE NOW
            </button>
            <!-- mobile dropdown -->
            <div class="lg:hidden">
                <Dropdown
                    @optionOnclick="menuItemSelected"
                    :items="menuItems"
                    :show-menu.sync="showMenu"
                    :align="'right'"
                >
                    <template #toggle-button>
                        <button class="flex items-center">
                            <svg-icon
                                type="mdi"
                                :path="mdiMenu"
                                size="36"
                                class="text-[#fff]"
                            ></svg-icon>
                        </button>
                    </template>
                </Dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMenu } from "@mdi/js";
import Dropdown from "./components/Dropdown.vue";

export default {
    name: "Header",
    components: {
        SvgIcon,
        Dropdown,
    },
    data: () => ({
        showMenu: false,
        mdiMenu,
        menuItems: [
            {
                text: "HOME",
            },
            {
                text: "ABOUT",
            },
            {
                text: "OUR TEAM",
            },
            {
                text: "WHITEPAPER",
            },
            {
                text: "DAO",
            },
        ],
    }),
    methods: {
        menuItemSelected(menuItem) {
            console.log("menu item selected: ", menuItem);
        },
        stakeNowBtnOnclick() {
            window.open("https://t.me/safetytonproto_bot");
        },
    },
};
</script>

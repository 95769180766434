<template>
    <div class="flex w-full flex-col gap-[60px] lg:gap-[80px]">
        <div class="flex w-full px-8 lg:px-[120px]">
            <div
                class="mx-auto flex w-full max-w-[1200px] items-center justify-between gap-10"
            >
                <!-- title -->
                <span class="text-2xl font-extrabold text-[#fff] lg:text-5xl"
                    >ROADMAP</span
                >
                <!-- description -->
                <span class="text-sm font-bold text-[#EBDAFE] lg:text-base"
                    >Our Plans For 2024 - 2025</span
                >
            </div>
        </div>
        <!-- card row -->
        <CardRow class="lg:hidden" />
        <CardRowDesktop class="hidden lg:flex" />
    </div>
</template>

<script>
import CardRow from "./components/CardRow.vue";
import CardRowDesktop from "./components/CardRowDesktop.vue";

export default {
    name: "Section3",
    components: {
        CardRow,
        CardRowDesktop,
    },
};
</script>

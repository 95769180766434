<template>
    <div class="flex w-full flex-col gap-16 pb-10 lg:gap-[120px] lg:pb-[100px]">
        <HeroSection />
        <Section2 />
        <Section3 />
        <Section4 />
    </div>
</template>

<script>
import HeroSection from "./components/HeroSection.vue";
import Section2 from "./components/section2/Section2.vue";
import Section3 from "./components/section3/Section3.vue";
import Section4 from "./components/section4/Section4.vue";

export default {
    name: "Home",
    components: {
        HeroSection,
        Section2,
        Section3,
        Section4,
    },
};
</script>

<template>
    <div
        class="flex w-full flex-col gap-[60px] px-8 lg:gap-[80px] lg:px-[120px]"
    >
        <div class="mx-auto flex w-full max-w-[1200px] flex-col gap-3">
            <!-- title -->
            <span class="text-2xl font-extrabold text-[#fff] lg:text-5xl"
                >MEET OUR TEAM</span
            >
            <!-- description -->
            <span class="text-sm text-[#fff] lg:text-base"
                >Account Managers</span
            >
        </div>
        <!-- card row -->
        <CardRow class="lg:hidden" />
        <CardRowDesktop class="hidden lg:flex" />
    </div>
</template>

<script>
import CardRow from "./components/CardRow.vue";
import CardRowDesktop from "./components/CardRowDesktop.vue";

export default {
    name: "Section4",
    components: {
        CardRow,
        CardRowDesktop,
    },
};
</script>

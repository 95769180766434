<template>
    <div
        class="section2-card-row h-[480px] w-full overflow-hidden px-8 lg:mx-auto"
    >
        <Splide
            :options="{
                loop: true,
                start: 1,
                rewind: true,
                focus: 'center',
                drag: 'free',
                arrows: false,
                pagination: false,
                gap: '10px',
                fixedWidth: '300px',
            }"
        >
            <SplideSlide>
                <div class="flex h-[480px] items-center justify-center">
                    <div
                        class="content mx-auto flex w-[300px] flex-col items-center gap-5 rounded-2xl px-5 pt-10"
                    >
                        <img
                            src="assets/imgs/views/home/section2/card1.png"
                            alt=""
                            class="w-[120px]"
                        />
                        <span
                            class="text-center text-base font-bold text-[#fff]"
                            >COMMUNITY ORIENTED</span
                        >
                        <span
                            class="description block text-center text-sm text-[#fff]"
                            >Our protocol is community-oriented, fostering
                            collaboration and inclusivity. By engaging users in
                            decision making and dispute resolution through our
                            DAO, we ensure transparency, trust, and a supportive
                            trading experience.</span
                        >
                    </div>
                </div>
            </SplideSlide>
            <SplideSlide>
                <div class="flex h-[480px] items-center justify-center">
                    <div
                        class="content mx-auto flex w-[300px] flex-col items-center gap-5 rounded-2xl px-5 pt-10"
                    >
                        <img
                            src="assets/imgs/views/home/section2/card2.png"
                            alt=""
                            class="w-[120px]"
                        />
                        <span
                            class="text-center text-base font-bold text-[#fff]"
                            >DECENTRALIZED</span
                        >
                        <span
                            class="description block text-center text-sm text-[#fff]"
                            >Guarantee Protocol offers a decentralized assurance
                            solution through Smart Contracts. Transparency are
                            ensured, with all guarantees kept on the TON
                            blockchain. Disputes are resolved democratically via
                            our DAO, providing a fair and unbiased outcome for
                            all parties.</span
                        >
                    </div>
                </div>
            </SplideSlide>
            <SplideSlide>
                <div class="flex h-[480px] items-center justify-center">
                    <div
                        class="content mx-auto flex w-[300px] flex-col items-center gap-5 rounded-2xl px-5 pt-10"
                    >
                        <img
                            src="assets/imgs/views/home/section2/card3.png"
                            alt=""
                            class="w-[120px]"
                        />
                        <span
                            class="text-center text-base font-bold text-[#fff]"
                            >REWARDS FOR ADOPTERS</span
                        >
                        <span
                            class="description block text-center text-sm text-[#fff]"
                            >We offers rewards for our early adopters,
                            incentivizing engagement. By joining our community
                            and utilizing our services, users can earn rewards,
                            enhancing the value and appeal of adopting our
                            innovative trade assurance solution.</span
                        >
                    </div>
                </div>
            </SplideSlide>
        </Splide>
    </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
    name: "CardRow",
    components: {
        Splide,
        SplideSlide,
    },
};
</script>

<style lang="scss">
.splide__track {
    overflow: visible !important;
}

.section2-card-row {
    .splide__slide {
        .content {
            background: #270656;
            transition: all 0.3s;
            height: 320px;
            border: 4px solid #270656;

            .description {
                transition: all 0.3s;
                height: 0;
                overflow: hidden;
            }
        }

        &.is-active {
            .content {
                height: 480px;
                background: #3a0b7e;
                border: 4px solid #ffc601;

                .description {
                    height: auto;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .section2-card-row {
        .splide__slide {
            .content {
                height: 480px;
                background: #3a0b7e;
                border: 4px solid #ffc601;

                .description {
                    height: auto;
                }
            }

            &.is-active {
                .content {
                    height: 480px;
                    background: #3a0b7e;
                    border: 4px solid #ffc601;

                    .description {
                        height: auto;
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div class="h-[480px] w-full overflow-hidden px-8 lg:mx-auto lg:px-[120px]">
        <Splide
            :options="{
                loop: true,
                start: 0,
                rewind: true,
                focus: 'center',
                drag: 'free',
                arrows: false,
                pagination: false,
                gap: '10px',
                fixedWidth: '280px',
            }"
        >
            <!-- member 1 -->
            <SplideSlide>
                <div class="flex h-[400px] items-center justify-center">
                    <div
                        class="content mx-auto flex h-[400px] w-[280px] flex-col gap-5 rounded-2xl bg-[#270656] p-4"
                    >
                        <!-- avatar -->
                        <img
                            src="assets/imgs/views/home/section4/avatar1.png"
                            alt=""
                            class="w-full rounded-xl"
                        />
                        <!-- name & @ -->
                        <div class="flex w-full flex-col gap-1">
                            <span class="text-lg font-bold text-[#fff]"
                                >John Karony</span
                            >
                            <span class="text-sm text-[#8E7DA7]"
                                >@johnguragoo_tonsafety</span
                            >
                        </div>
                        <!-- position -->
                        <span class="text-sm text-[#fff]"
                            >Cryptocurrency Trading</span
                        >
                    </div>
                </div>
            </SplideSlide>
            <!-- member 2 -->
            <SplideSlide>
                <div class="flex h-[400px] items-center justify-center">
                    <div
                        class="content mx-auto flex h-[400px] w-[280px] flex-col gap-5 rounded-2xl bg-[#270656] p-4"
                    >
                        <!-- avatar -->
                        <img
                            src="assets/imgs/views/home/section4/avatar2.png"
                            alt=""
                            class="w-full rounded-xl"
                        />
                        <!-- name & @ -->
                        <div class="flex w-full flex-col gap-1">
                            <span class="text-lg font-bold text-[#fff]"
                                >Thomas Smith</span
                            >
                            <span class="text-sm text-[#8E7DA7]"
                                >@thomaswooloo_tonsafety</span
                            >
                        </div>
                        <!-- position -->
                        <span class="text-sm text-[#fff]"
                            >Payment / E-Commerce</span
                        >
                    </div>
                </div>
            </SplideSlide>
            <!-- member 3 -->
            <SplideSlide>
                <div class="flex h-[400px] items-center justify-center">
                    <div
                        class="content mx-auto flex h-[400px] w-[280px] flex-col gap-5 rounded-2xl bg-[#270656] p-4"
                    >
                        <!-- avatar -->
                        <img
                            src="assets/imgs/views/home/section4/avatar3.png"
                            alt=""
                            class="w-full rounded-xl"
                        />
                        <!-- name & @ -->
                        <div class="flex w-full flex-col gap-1">
                            <span class="text-lg font-bold text-[#fff]"
                                >Math Trella</span
                            >
                            <span class="text-sm text-[#8E7DA7]"
                                >@math_tonsafety</span
                            >
                        </div>
                        <!-- position -->
                        <span class="text-sm text-[#fff]"
                            >Payment / Cryptocurrency</span
                        >
                    </div>
                </div>
            </SplideSlide>
            <!-- member 4 -->
            <SplideSlide>
                <div class="flex h-[400px] items-center justify-center">
                    <div
                        class="content mx-auto flex h-[400px] w-[280px] flex-col gap-5 rounded-2xl bg-[#270656] p-4"
                    >
                        <!-- avatar -->
                        <img
                            src="assets/imgs/views/home/section4/avatar4.png"
                            alt=""
                            class="w-full rounded-xl"
                        />
                        <!-- name & @ -->
                        <div class="flex w-full flex-col gap-1">
                            <span class="text-lg font-bold text-[#fff]"
                                >Rammel Donnald</span
                            >
                            <span class="text-sm text-[#8E7DA7]"
                                >@Rammel_tonsafety</span
                            >
                        </div>
                        <!-- position -->
                        <span class="text-sm text-[#fff]"
                            >Internet / Games</span
                        >
                    </div>
                </div>
            </SplideSlide>
        </Splide>
    </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
    name: "CardRow",
    components: {
        Splide,
        SplideSlide,
    },
};
</script>

<style lang="scss">
.splide__track {
    overflow: visible !important;
}
</style>

<template>
    <div class="flex w-screen flex-col">
        <!-- divider -->
        <div
            class="h-1 w-full bg-gradient-to-r from-[#FFC601] to-[#ED3983]"
        ></div>
        <!-- content -->
        <div class="w-full px-8 lg:px-[120px]">
            <div
                class="mx-auto flex w-full flex-col gap-10 py-8 pt-4 lg:max-w-[1200px] lg:flex-row lg:items-end lg:justify-between lg:gap-20"
            >
                <div class="flex flex-grow gap-5">
                    <img
                        src="assets/imgs/utils/logo/logo.png"
                        alt=""
                        class="hidden h-[160px] lg:block"
                    />
                    <!-- main content -->
                    <div class="flex w-full flex-col gap-5 lg:max-w-[600px]">
                        <!-- mobile logo -->
                        <a href="/">
                            <img
                                src="assets/imgs/utils/logo/ton_safety.png"
                                alt="ton_safety"
                                class="w-[200px] lg:hidden"
                            />
                        </a>
                        <span
                            class="hidden text-2xl font-bold text-[#fff] lg:block"
                            >TON Safety</span
                        >
                        <!-- description -->
                        <span class="text-base text-[#DCC9FF]"
                            >TON Safety is a blockchain-based company offering
                            secure trade assurance services through our TON
                            Guarantee Protocol. Built on the TON blockchain, we
                            provide a transparent and decentralized solution for
                            transaction guarantees. Our mission is to ensure
                            trust and security in digital transactions, allowing
                            users to trade with confidence.</span
                        >
                        <!-- copyright -->
                        <span class="text-sm text-[#6D5D82]"
                            >COPYRIGHT 2024 Ton Safety LLC. | ALL RIGHTS
                            RESERVED</span
                        >
                    </div>
                </div>
                <!-- contact links -->
                <div class="flex flex-col gap-5 lg:gap-20">
                    <!-- contacts -->
                    <div class="flex items-center gap-2">
                        <a
                            class="flex h-16 w-16 items-center justify-center rounded-full border-2 border-[#503290] hover:border-0 hover:bg-gradient-to-br hover:from-[#FFC601] hover:to-[#FE2AA3]"
                        >
                            <img
                                src="assets/imgs/utils/socialMedia/facebook.png"
                                alt=""
                                class="w-5"
                            />
                        </a>
                        <a
                            class="flex h-16 w-16 items-center justify-center rounded-full border-2 border-[#503290] hover:border-0 hover:bg-gradient-to-br hover:from-[#FFC601] hover:to-[#FE2AA3]"
                        >
                            <img
                                src="assets/imgs/utils/socialMedia/telegram.png"
                                alt=""
                                class="w-5"
                            />
                        </a>
                        <a
                            class="flex h-16 w-16 items-center justify-center rounded-full border-2 border-[#503290] hover:border-0 hover:bg-gradient-to-br hover:from-[#FFC601] hover:to-[#FE2AA3]"
                        >
                            <img
                                src="assets/imgs/utils/socialMedia/twitter.png"
                                alt=""
                                class="w-5"
                            />
                        </a>
                        <a
                            class="flex h-16 w-16 items-center justify-center rounded-full border-2 border-[#503290] hover:border-0 hover:bg-gradient-to-br hover:from-[#FFC601] hover:to-[#FE2AA3]"
                        >
                            <img
                                src="assets/imgs/utils/socialMedia/medium.png"
                                alt=""
                                class="w-5"
                            />
                        </a>
                    </div>
                    <!-- terms of service -->
                    <div class="flex items-center gap-10">
                        <a
                            href=""
                            class="text-nowrap text-sm text-[#fff] hover:text-[#fff]"
                            >TERMS OF SERVICE</a
                        >
                        <a
                            href=""
                            class="text-nowrap text-sm text-[#fff] hover:text-[#fff]"
                            >PRIVACY POLICY</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
};
</script>

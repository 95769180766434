<template>
    <div id="app" class="bg-[#170038]">
        <Header />
        <div class="flex w-screen flex-col">
            <router-view />
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";

export default {
    name: "App",
    components: {
        Header,
        Footer,
    },
};
</script>

<template>
    <div
        class="section3-card-row h-[480px] w-full overflow-hidden px-8 lg:mx-auto lg:px-[120px]"
    >
        <Splide
            :options="{
                loop: true,
                start: 1,
                rewind: true,
                focus: 'center',
                drag: 'free',
                arrows: false,
                pagination: false,
                gap: '10px',
                fixedWidth: '280px',
            }"
        >
            <!-- phase 1 -->
            <SplideSlide>
                <div class="flex h-[480px] items-center justify-center">
                    <div
                        class="content mx-auto flex w-[280px] flex-col gap-5 rounded-2xl px-5 pt-8"
                    >
                        <div class="flex w-full flex-col gap-2">
                            <span
                                class="phase-title text-4xl font-bold text-[#fff]"
                                >PHASE I</span
                            >
                            <span class="phase-time text-base text-[#F72998]"
                                >2024 / 01</span
                            >
                            <img
                                src="assets/imgs/views/home/section3/card-img.png"
                                alt=""
                                class="card-img w-[80px] animate-spin"
                            />
                        </div>
                        <div class="flex w-full flex-col gap-4">
                            <span class="text-xl font-bold text-[#fff]"
                                >INITIALIZATION</span
                            >
                            <span class="description block text-sm text-[#fff]"
                                >We focus on establishing the foundation of our
                                TON Guarantee Protocol.<br /><br />
                                This phase involves deploying our smart contract
                                infrastructure on the TON blockchain, setting up
                                our decentralized assurance system, and
                                launching our Telegram bot for community
                                engagement.
                            </span>
                        </div>
                    </div>
                </div>
            </SplideSlide>
            <!-- phase 2 -->
            <SplideSlide>
                <div class="flex h-[480px] items-center justify-center">
                    <div
                        class="content mx-auto flex w-[280px] flex-col gap-5 rounded-2xl px-5 pt-8"
                    >
                        <div class="flex w-full flex-col gap-2">
                            <span
                                class="phase-title text-4xl font-bold text-[#fff]"
                                >PHASE II</span
                            >
                            <span class="phase-time text-base text-[#F72998]"
                                >2024 / 03</span
                            >
                            <img
                                src="assets/imgs/views/home/section3/card-img.png"
                                alt=""
                                class="card-img w-[80px] animate-spin"
                            />
                        </div>
                        <div class="flex w-full flex-col gap-4">
                            <span class="text-xl font-bold text-[#fff]"
                                >EARLY ADOPTION</span
                            >
                            <span class="description block text-sm text-[#fff]"
                                >We aim to integrate our Guarantee Protocol with
                                numerous Telegram communities via our Telegram
                                bot.<br /><br />This will enable these
                                communities to easily incorporate staking and
                                dealing mechanisms, enhancing the utility of our
                                protocol and providing a secure platform for
                                users to engage in guarantee activities</span
                            >
                        </div>
                    </div>
                </div>
            </SplideSlide>
            <!-- phase 3 -->
            <SplideSlide>
                <div class="flex h-[480px] items-center justify-center">
                    <div
                        class="content mx-auto flex w-[280px] flex-col gap-5 rounded-2xl px-5 pt-8"
                    >
                        <div class="flex w-full flex-col gap-2">
                            <span
                                class="phase-title text-4xl font-bold text-[#fff]"
                                >PHASE III</span
                            >
                            <span class="phase-time text-base text-[#F72998]"
                                >2024 / 06</span
                            >
                            <img
                                src="assets/imgs/views/home/section3/card-img.png"
                                alt=""
                                class="card-img w-[80px] animate-spin"
                            />
                        </div>
                        <div class="flex w-full flex-col gap-4">
                            <span class="text-xl font-bold text-[#fff]"
                                >MARKET EXPANSION</span
                            >
                            <span class="description block text-sm text-[#fff]"
                                >Our goal is to broaden the reach and impact of
                                our protocol.<br /><br />We focus on strategic
                                partnerships and expanding our presence in
                                various market segments.<br /><br />By
                                increasing accessibility and enhancing our
                                offerings, we aim to establish our protocol as a
                                leading solution for trade assurance in the
                                blockchain ecosystem.</span
                            >
                        </div>
                    </div>
                </div>
            </SplideSlide>
            <!-- phase 4 -->
            <SplideSlide>
                <div class="flex h-[480px] items-center justify-center">
                    <div
                        class="content mx-auto flex w-[280px] flex-col gap-5 rounded-2xl px-5 pt-8"
                    >
                        <div class="flex w-full flex-col gap-2">
                            <span
                                class="phase-title text-4xl font-bold text-[#fff]"
                                >PHASE IV</span
                            >
                            <span class="phase-time text-base text-[#F72998]"
                                >2025</span
                            >
                            <img
                                src="assets/imgs/views/home/section3/card-img.png"
                                alt=""
                                class="card-img w-[80px] animate-spin"
                            />
                        </div>
                        <div class="flex w-full flex-col gap-4">
                            <span class="text-xl font-bold text-[#fff]"
                                >FULL ADOPTION</span
                            >
                            <span class="description block text-sm text-[#fff]"
                                >Our goal is to achieve widespread acceptance of
                                our TON Guarantee Protocol. We'll focus on
                                scalability, seamless integration, and fostering
                                a vibrant community.<br /><br />By enhancing
                                user experience and continuously innovating, we
                                aim to establish our protocol as an industry
                                standard for secure and transparent trade
                                assurance in the blockchain space.</span
                            >
                        </div>
                    </div>
                </div>
            </SplideSlide>
        </Splide>
    </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
    name: "CardRow",
    components: {
        Splide,
        SplideSlide,
    },
};
</script>

<style lang="scss">
.splide__track {
    overflow: visible !important;
}

.section3-card-row {
    .splide__slide {
        .content {
            background: #270656;
            transition: all 0.3s;
            height: 320px;
            border: 4px solid #270656;
            gap: 40px;

            .phase-title {
                transition: all 0.3s;
                font-size: 20px;
            }

            .phase-time {
                transition: all 0.3s;
                color: #3372b7;
            }

            .card-img {
                width: 80px;
                transition: all 0.3s;
                margin: 0 auto;
                opacity: 1;
            }

            .description {
                transition: all 0.3s;
                height: 0;
                overflow: hidden;
            }
        }

        &.is-active {
            .content {
                height: 480px;
                background: #3a0b7e;
                border: 4px solid #ffc601;
                gap: 20px;

                .phase-title {
                    font-size: 36px;
                }

                .phase-time {
                    transition: all 0.3s;
                    color: #f72998;
                }

                .card-img {
                    width: 0px;
                    opacity: 0;
                }

                .description {
                    height: auto;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .splide__list {
        margin: 0 auto;
    }

    .section3-card-row {
        .splide__slide {
            .content {
                height: 480px;
                background: #3a0b7e;
                border: 4px solid #ffc601;
                gap: 20px;

                .phase-title {
                    font-size: 36px;
                }

                .phase-time {
                    transition: all 0.3s;
                    color: #f72998;
                }

                .card-img {
                    width: 0px;
                    opacity: 0;
                }

                .description {
                    height: auto;
                }
            }
        }
    }
}
</style>

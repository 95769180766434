<template>
    <div
        class="relative flex w-full flex-col gap-[60px] px-8 pt-10 lg:gap-[120px] lg:px-[120px] lg:pt-[100px]"
    >
        <!-- bg -->
        <img
            src="assets/imgs/views/home/heroSection/hero_section-bg.png"
            alt=""
            class="absolute right-0 top-0 z-0 w-full opacity-30 lg:max-w-[1000px] lg:opacity-100"
        />
        <!-- intro -->
        <div
            class="z-1 relative flex w-full flex-col gap-5 lg:mx-auto lg:max-w-[1200px] lg:gap-12"
        >
            <!-- text group -->
            <div class="flex w-full flex-col gap-5 lg:w-1/2">
                <!-- title -->
                <span class="text-4xl font-extrabold text-[#fff] lg:text-7xl"
                    >GUARANTEE PROTOCOL</span
                >
                <!-- description -->
                <span class="text-base text-[#D4B9F4]"
                    >A robust trade assurance protocol on the TON chain, serving
                    as your guardian in transactions. Secure your deals with our
                    stake-based assurance. Trade with confidence, knowing you're
                    backed by a reliable and transparent safety net.</span
                >
            </div>
            <!-- buttons -->
            <div class="flex flex-wrap items-center gap-4 lg:gap-8">
                <a
                    href="https://t.me/safetytonproto_bot"
                    class="flex h-12 items-center justify-center text-nowrap rounded-full bg-gradient-to-r from-[#FFC601] to-[#FE2AA3] px-5 text-lg font-bold text-[#fff] hover:text-[#fff] lg:max-w-[160px] lg:flex-grow"
                    >DEMO</a
                >
                <a
                    href=""
                    class="flex h-12 flex-nowrap items-center justify-center gap-2 rounded-full border-2 border-[#fff] px-5 text-lg font-bold text-[#fff]"
                >
                    <span class="text-nowrap text-base font-bold text-[#fff]"
                        >Connect API</span
                    >
                    <svg-icon
                        type="mdi"
                        :path="mdiChevronRight"
                        :size="16"
                        class="text-[#fff]"
                    ></svg-icon>
                </a>
            </div>
        </div>
        <!-- ton eco buttons -->
        <div
            class="z-1 relative flex flex-wrap items-center gap-5 lg:mx-auto lg:max-w-[1200px] lg:gap-10"
        >
            <!-- ton -->
            <a href="" class="flex flex-nowrap items-center gap-2 lg:gap-4">
                <img
                    src="assets/imgs/views/home/heroSection/button-ton.png"
                    alt=""
                    class="h-8 w-8 lg:h-12 lg:w-12"
                />
                <span
                    class="text-nowrap text-lg font-bold text-[#fff] lg:text-2xl"
                    >TON Blockchain</span
                >
            </a>
            <!-- telegram -->
            <a
                href="https://t.me/safetytonproto_bot"
                class="flex flex-nowrap items-center gap-2 lg:gap-4"
            >
                <img
                    src="assets/imgs/views/home/heroSection/button-telegram.png"
                    alt=""
                    class="h-8 w-8 lg:h-12 lg:w-12"
                />
                <span
                    class="text-nowrap text-lg font-bold text-[#fff] lg:text-2xl"
                    >Telegram Bot</span
                >
            </a>
        </div>
        <!-- statistics -->
        <div
            class="z-1 relative flex w-full flex-wrap gap-5 rounded-[20px] border-4 border-[#FFC601] py-6 lg:mx-auto lg:max-w-[1200px] lg:gap-10 lg:border-[6px] lg:py-14"
        >
            <!-- total vendors -->
            <div
                class="flex w-[calc(50%-20px)] flex-col items-center justify-center gap-2 lg:w-[calc(25%-30px)] lg:gap-4"
            >
                <span class="text-xl font-bold text-[#fff] lg:text-3xl"
                    >5,236</span
                >
                <div class="h-1 w-6 rounded-full bg-[#3F1057]"></div>
                <span
                    class="flex h-[44px] items-center justify-center text-center text-base text-[#fff] lg:text-lg"
                    >TOTAL VENDORS</span
                >
            </div>
            <!-- total staked -->
            <div
                class="flex w-[calc(50%-20px)] flex-col items-center justify-center gap-2 lg:w-[calc(25%-30px)] lg:gap-4"
            >
                <span class="text-xl font-bold text-[#fff] lg:text-3xl"
                    >$68,235,190.10</span
                >
                <div class="h-1 w-6 rounded-full bg-[#3F1057]"></div>
                <span
                    class="flex h-[44px] items-center justify-center text-center text-base text-[#fff] lg:text-lg"
                    >TOTAL STAKED</span
                >
            </div>
            <!-- TRADES GUARANTEED -->
            <div
                class="flex w-[calc(50%-20px)] flex-col items-center justify-center gap-2 lg:w-[calc(25%-30px)] lg:gap-4"
            >
                <span class="text-xl font-bold text-[#fff] lg:text-3xl"
                    >52,961</span
                >
                <div class="h-1 w-6 rounded-full bg-[#3F1057]"></div>
                <span
                    class="flex h-[44px] items-center justify-center text-center text-base text-[#fff] lg:text-lg"
                    >TRADES GUARANTEED</span
                >
            </div>
            <!-- DIVIDENDS -->
            <div
                class="flex w-[calc(50%-20px)] flex-col items-center justify-center gap-2 lg:w-[calc(25%-30px)] lg:gap-4"
            >
                <span class="text-xl font-bold text-[#fff] lg:text-3xl"
                    >$313,725</span
                >
                <div class="h-1 w-6 rounded-full bg-[#3F1057]"></div>
                <span
                    class="flex h-[44px] items-center justify-center text-center text-base text-[#fff] lg:text-lg"
                    >DIVIDENDS</span
                >
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight } from "@mdi/js";

export default {
    name: "HerSection",
    components: {
        SvgIcon,
    },
    data: () => ({
        mdiChevronRight,
    }),
};
</script>
